/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import "../header/css/index.css"
import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
	Drawer,
	Button,
	Menu,
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"

export default function Header(props) {
	const navigate = useNavigate()
	const location = useLocation()

	const { width } = useDimensions()

	const [state, setState] = useState({
		current: 'mail',
		visible: false
	})

	const showDrawer = () => {
		setState({ visible: true })
	}

	const onClose = () => {
		setState({ visible: false })
	}

	const genRightMenu = () => {
		return (
			<Menu mode="inline">
				<Menu.Item
					key="1"
					onClick={() => {
						navigate("/etimate-price")
						window.scrollTo(0, 0)
					}}
				>
					ประเมินราคาโทรศัพท์
				</Menu.Item>

				<Menu.Item
					key="2"
					onClick={() => {
						navigate("/mobile-pawn")
						window.scrollTo(0, 0)
					}}
				>
					จำนำมือถือ
				</Menu.Item>

				<Menu.Item
					key="3"
					onClick={() => {
						navigate("/mobile-purchase")
						window.scrollTo(0, 0)
					}}
				>
					รับซื้อโทรศัพท์
				</Menu.Item>

				<Menu.Item
					key="4"
					onClick={() => {
						navigate("/contact-us")
						window.scrollTo(0, 0)
					}}
				>
					ติดต่อเรา
				</Menu.Item>
			</Menu>
		)
	}

	const genLeftMenu = () => {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					fontSize: 18,
					marginTop: -2
				}}
			>
				<div
					onClick={() => {
						navigate("/etimate-price")
						window.scrollTo(0, 0)
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>ประเมินราคาโทรศัพท์</label>
					</div>
				</div>

				<div
					onClick={() => {
						navigate("/mobile-pawn")
						window.scrollTo(0, 0)
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>จำนำมือถือ</label>
					</div>
				</div>

				<div
					onClick={() => {
						navigate("/mobile-purchase")
						window.scrollTo(0, 0)
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>รับซื้อโทรศัพท์</label>
					</div>
				</div>

				<div
					onClick={() => {
						navigate("/contact-us")
						window.scrollTo(0, 0)
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>ติดต่อเรา</label>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div
			style={{
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0,
				position: 'fixed',
				zIndex: 2,
				width: "100%",
				backgroundColor: 'rgba(255, 153, 0, 0.7)',
				// backgroundColor: "#FF9900",
				overflow: 'hidden',
				boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
				height: 70,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between"
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					paddingLeft: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 24 : 12)
				}}
			>
				<div style={{ display: "grid", alignItems: "center", color: "white" }}>
					<img
						src={"./assets/images/logo/logo-kingdom-store.jpg"}
						alt="Logo Kingdom Store"
						style={{ width: 55, height: 55, borderRadius: 60, cursor: "pointer" }}
						onClick={() => {
							navigate("/")
						}}
					/>
				</div>

				<div>
					{location.pathname === "/mobile-purchase" ?
						<label style={{ fontSize: 22, color: "white", paddingLeft: 12 }}>รับซื้อโทรศัพท์</label>
						: []
					}

					{location.pathname === "/mobile-pawn" ?
						<label style={{ fontSize: 22, color: "white", paddingLeft: 12 }}>จำนำมือถือ</label>
						: []
					}

					{location.pathname === "/" || location.pathname === "/contact-us" || location.pathname === "/etimate-price" ?
						<label style={{ fontSize: 22, color: "white", paddingLeft: 12 }}>Kingdom Store</label>
						: []
					}
				</div>
			</div>

			{width > 1012 ?
				<div
					style={{
						display: "flex",
						paddingTop: 2,
						alignItems: "center",
						justifyContent: "flex-end",
						paddingRight: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 24 : 12)
					}}
				>
					{genLeftMenu()}
				</div>
				:
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
					<Drawer
						title={<label style={{ fontSize: 16, fontWeight: 100 }}>Kingdom Store</label>} placement="right"
						closable={false}
						onClose={onClose}
						visible={state.visible}
						bodyStyle={{ padding: '0px' }}
						size="default"
					>
						{genRightMenu()}
					</Drawer>

					<Button
						type="primary"
						onClick={showDrawer}
						style={{
							height: 70,
							width: 70,
							borderRadius: 0
						}}
					>
						<Icon
							icon="charm:menu-hamburger"
							style={{
								fontSize: '2.5em',
								marginTop: 4,
								color: "white"
							}}
						/>
					</Button>
				</div>
			}
		</div>
	)
}