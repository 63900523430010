/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Select
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

import "./css/index.css"
import { useNavigate } from 'react-router'
import CorousBanner from './CorousBanner'
import { getBannerFetch, getMobileBrandFetch, getMobileCapacityFetch, getMobileModelFetch } from '../../API/estimatePriceApi'
import { color, fontWeight } from '@mui/system'

const { Option } = Select

export default function Main() {

    const [form] = Form.useForm()
    const { width } = useDimensions()

    const navigate = useNavigate()

    const [mobileBrand, setMobileBrand] = useState([])
    const [mobileModel, setMobileModel] = useState([])
    const [mobileCapacity, setMobileCapacity] = useState([])
    const [banner, setBanner] = useState([])

    // const [banner, setBanner] = useState([
    //     { image: "./assets/images/banner/bn1.jpg", title: "xxx" },
    //     { image: "./assets/images/banner/bn2.jpg", title: "xxx" },
    //     { image: "./assets/images/banner/bn3.jpg", title: "xxx" }
    // ])

    const onFinish = async (values) => {
        let brand = values.brand ? values.brand : null
        let model = values.model ? values.model : null
        let capacity = values.capacity ? values.capacity : null

        // console.log("X --- : ", brand, model, capacity)
        navigate("/evaluate-integrity?brand=" + brand + "&model=" + model + "&capacity=" + capacity)

        // set default
        setFormDefault()
    }

    const setFormDefault = () => {
        form.resetFields()
    }

    const getMobileBrand = async () => {
        const result = await getMobileBrandFetch(null, null, null)
        // console.log("getMobileBrandFetch : ", result?.result)

        if (result?.status === 200) {
            setMobileBrand(result?.result)
        }
    }

    const getMobileModel = async (id) => {
        let param = {
            mobileBrandId: id
        }
        const result = await getMobileModelFetch(param, null, null)
        // console.log("getMobileModelFetch : ", result?.result)
        if (result?.status === 200) {
            setMobileModel(result?.result)
        }
    }

    const getMobileCapacity = async (id) => {
        let param = {
            mobileModelId: id
        }
        const result = await getMobileCapacityFetch(param, null, null)
        // console.log("getMobileCapacityFetch : ", result?.result)
        if (result?.status === 200) {
            setMobileCapacity(result?.result)
        }
    }

    const getBanner = async (id) => {
        let param = {
            mobileModelId: id
        }
        const result = await getBannerFetch(param, null, null)
        // console.log("getBannerFetch : ", result?.result)
        if (result?.status === 200) {
            setBanner(result?.result)
        }
    }

    const getBaseApi = async () => {
        await getMobileBrand()
        await getBanner()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <div
                style={{
                    backgroundColor: "#FFBB55",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: width > 1200 ? 150 : (width > 768 && width < 1200 ? 150 : 94),
                        paddingBottom: (width > 768 ? 82 : 24),

                        maxWidth: 1000
                    }}
                >

                    <Row gutter={[0, 24]}>
                        <Col xs={24} md={12} xl={12}>
                            <CorousBanner data={banner} isNavigation={false} />
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <div
                                style={{
                                    paddingLeft: 24,
                                    paddingRight: 24
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        borderRadius: 16,
                                        paddingRight: 24,
                                        paddingLeft: 24,
                                        paddingTop: 18,
                                        paddingBottom: 18,
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%"
                                        }}
                                    >
                                        <Form
                                            layout="vertical"
                                            form={form}
                                            onFinish={onFinish}
                                        >
                                            <Row gutter={[24, 4]}>
                                                <Col span={24}>
                                                    <div style={{ paddingBottom: 8 }}>
                                                        <label className='ks-item-title'>ประเมินราคาโทรศัพท์ที่ต้องการขาย</label>
                                                    </div>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        name="brand"
                                                        rules={[{ required: true, message: 'กรุณาเลือกยี่ห้อโทรศัพท์' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder="เลือกยี่ห้อโทรศัพท์"
                                                            style={{ width: '100%' }}
                                                            optionFilterProp="children"
                                                            allowClear
                                                            size="large"
                                                            onChange={async (e) => {
                                                                await getMobileModel(e)
                                                            }}
                                                        >
                                                            {mobileBrand?.map((item, index) => {
                                                                return (<Option key={index} value={item?.id}>{item?.title}</Option>)
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        name="model"
                                                        rules={[{ required: true, message: 'กรุณาเลือกรุ่นโทรศัพท์' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder="เลือกรุ่นโทรศัพท์"
                                                            style={{ width: '100%' }}
                                                            optionFilterProp="children"
                                                            allowClear
                                                            size="large"
                                                            onChange={async (e) => {
                                                                await getMobileCapacity(e)
                                                            }}
                                                        >
                                                            {mobileModel?.map((item, index) => {
                                                                return (<Option key={index} value={item?.id}>{item?.title}</Option>)
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        name="capacity"
                                                        rules={[{ required: true, message: 'กรุณาเลือกความจุ GB' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder="เลือกความจุ GB"
                                                            style={{ width: '100%' }}
                                                            optionFilterProp="children"
                                                            allowClear
                                                            size="large"
                                                        >
                                                            {mobileCapacity?.map((item, index) => {
                                                                return (<Option key={index} value={item?.id}>{item?.capacity}</Option>)
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Button
                                                        type="primary"
                                                        size="large"
                                                        style={{ width: '100%' }}
                                                        onClick={() => {
                                                            window.scroll(0,0)
                                                            form.submit()
                                                        }}
                                                    >
                                                        <label className='ks-item-title' style={{ cursor: "pointer" }}>เริ่มที่นี่</label>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: "#FF9900",
                    paddingTop: 28,
                    paddingBottom: 28,
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <label className='ks-item-title' style={{ color: "white" }}>มาขายที่หน้าร้านรับเพิ่ม <soan style={{ color: "#FF0000", fontWeight: 600 }}>500</soan> บาท</label>
            </div>
        </>
    )
}