/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    Card,
    Select,
    Input,
    InputNumber,
    Checkbox
} from 'antd'
import { Icon } from '@iconify/react'

import { useNavigate } from 'react-router'
// import { useLocation } from "react-router-dom"
import { useDimensions } from '../../common/mamager/ScreenManager'

import Footer from '../../common/components/footer'
import Header from '../../common/components/header'

import dayjs from 'dayjs'
import 'dayjs/locale/th' // นำเข้า locale ภาษาไทย
import buddhistEra from 'dayjs/plugin/buddhistEra' // นำเข้า plugin สำหรับพุทธศักราช

import "./css/index.css"
import { Notification } from '../../common/components/notification'
import { getDistrictFetch, getProvinceFetch, getTrainFetch, getTrainLineFetch, getTrainStationFetch, insertSaleInfoFetch } from './API/summaryResultApi'
import moment from 'moment'
import StepSaleMobile from '../../common/components/step'
import { Modal } from 'antd/lib'

const { Option } = Select

dayjs.extend(buddhistEra);
dayjs.locale('th'); // ตั้งค่า locale ภาษาไทย

export default function SummaryResult(props) {

    const [form] = Form.useForm()
    const { width } = useDimensions()

    const navigate = useNavigate()

    const [saleInfo, setSaleInfo] = useState(JSON.parse(localStorage.getItem('saleInfo')))
    const [deviceInfo, setDeviceInfo] = useState(JSON.parse(localStorage.getItem('deviceInfo')))
    const [issueInfo, setIssueInfo] = useState(JSON.parse(localStorage.getItem('issueInfo')))

    // console.log("saleInfo : ", saleInfo)
    // console.log("deviceInfo : ", deviceInfo)
    // console.log("issueInfo : ", issueInfo)

    const [isSelectPlace, setIsSelectPlace] = useState("buy-at-home") // buy-along-train

    const [policy, setPolicy] = useState(true)

    const [modalPolicy, setModalPolicy] = useState({
        isShow: false
    })

    const onFinish = async (values) => {
        let issueOptions = []
        for (let i = 0; i < issueInfo.length; i++) {
            issueOptions.push({
                issueOptionsId: issueInfo[i].id
            })
        }

        let purchase = null
        if (isSelectPlace === "buy-at-home") {
            purchase = 1
        } else if (isSelectPlace === "buy-along-train") {
            purchase = 2
        } else if (isSelectPlace === "buy-at-store") {
            purchase = 3
        }

        let obj = {
            "mobileBrandId": deviceInfo?.mobileBrandId,
            "mobileModelId": deviceInfo?.mobileModelId,
            "mobileCapacityId": deviceInfo?.mobileCapacityId,
            "customerInfo": {
                "firtsName": values.name.split(" ")[0] ?? "-",
                "lastName": values.name.split(" ")[1] ?? "-",
                "telephone": values?.phoneNumber ? values.phoneNumber : null
            },
            "placePurchase": {
                "purchaseId": purchase,
                "districtId": values?.district ? values.district : null,
                "trainStationId": values?.trainStation ? values.trainStation : null
            },
            "appointmentDate": moment(`${values.day} ${values.time}`, 'DD/MM/YYYY HH:mm').add(7, 'hours').toISOString(),
            "address": values?.address ? values.address : "-",
            "totalSummary": saleInfo.summarySaleInfo,
            "isActive": true,
            "issueOptions": issueOptions
        }
        // console.log("obj --- : ", obj)

        if (values.phoneNumber === values.confirmPhoneNumber) {
            if (policy) {
                const result = await insertSaleInfoFetch(null, obj, null)
                if ([200, 201].includes(result?.status)) {
                    navigate("/result", {
                        state: {
                            type: 'success',
                            title: "ส่งข้อมูลเรียบร้อย",
                            subTitle: "โปรดรอทางเจ้าหน้าทีติดต่อกลับ เเละเข้าไปรับสินค้า",
                            btnIsActive1: true,
                            btnWidth: 250,
                            btnPathName1: "/etimate-price",
                            btnName1: "กลับไปหน้าหลัก"
                        }
                    })
                    Notification('success', 'สร้างสำเร็จ')
                } else {
                    Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
                }
            } else {
                Notification("error", "เเจ้งเตือน", "กรุณาคลิกยอมรับข้อตกลงและเงื่อนไข !")
            }
        } else {
            Notification("error", "เเจ้งเตือน", "ไม่สามารถส่งข้อมูลได้ กรุณาตรวจเบอร์โทร")
        }
    }



    const [province, setProvince] = useState()
    const [district, setDistrict] = useState()
    const [trainLine, setTrainLine] = useState()
    const [trainStation, setTrainStation] = useState()

    const today = dayjs()
    const [day, setDay] = useState()
    const [time, setTime] = useState()

    const genDay = () => {
        const options = [];
        for (let i = 0; i <= 7; i++) {
            const date = today.add(i, 'day');
            const formattedDate = date.format('D MMMM BBBB') // รูปแบบวันที่ "1 สิงหาคม 2567"
            const formatDate = date.format('DD/MM/YYYY')
            // console.log("x --- : ", formatDate);

            options.push(
                <Option key={i} value={formatDate}>
                    {formattedDate}
                </Option>
            );
        }
        // console.log("day --- : ", options)
        setDay(options)
    }

    const genTime = () => {
        const options = []
        for (let hour = 0; hour < 24; hour++) {
            // แปลงรูปแบบชั่วโมงเป็นสองหลัก เช่น 00, 01, 02,..., 23
            const hourStr = hour.toString().padStart(2, '0')

            // สร้างตัวเลือกสำหรับนาที 00
            options.push(
                <Option key={`${hourStr}:00`} value={`${hourStr}:00`}>
                    {`${hourStr}:00`}
                </Option>
            )

            // สร้างตัวเลือกสำหรับนาที 30
            options.push(
                <Option key={`${hourStr}:30`} value={`${hourStr}:30`}>
                    {`${hourStr}:30`}
                </Option>
            )
        }
        // console.log("time --- : ", options)
        setTime(options)
    }

    const getProvince = async () => {
        const result = await getProvinceFetch()
        // console.log("getProvinceFetch : ", result?.result)
        if (result?.status === 200) {
            setProvince(result?.result)
        }
    }

    const getDistrict = async (id) => {
        let param = {
            id
        }
        const result = await getDistrictFetch(param, null, null)
        // console.log("getDistrictFetch : ", result?.result)
        if (result?.status === 200) {
            setDistrict(result?.result)
        }
    }

    const getTrainLine = async () => {
        let param = {}
        const result = await getTrainLineFetch(param, null, null)
        // console.log("getTrainLineFetch : ", result?.result)
        if (result?.status === 200) {
            setTrainLine(result?.result)
        }
    }

    const getTrainStation = async (id) => {
        let param = {
            trainCategoriesId: id
        }
        const result = await getTrainStationFetch(param, null, null)
        // console.log("getTrainStationFetch : ", result?.result)
        if (result?.status === 200) {
            setTrainStation(result?.result)
        }
    }

    const getBaseApi = async () => {
        genDay()
        genTime()
        getProvince()
        getDistrict()
        getTrainLine()

        form.setFieldValue("policy", true)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Header />

            <div
                style={{
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        paddingTop: width > 1200 ? 150 : (width > 760 && width < 1200 ? 150 : 150),
                        paddingLeft: 24,
                        paddingRight: 24
                    }}
                >
                    <StepSaleMobile stepId={2} />
                </div>
                <div
                    style={{
                        display: (width > 1050 ? "flex" : "grid"),
                        alignItems: "flex-start",
                        paddingTop: 48,
                        paddingBottom: 82,
                        paddingLeft: (width > 1200 ? 0 : 24),
                        paddingRight: (width > 1200 ? 0 : 24),
                        maxWidth: 1000
                    }}
                >
                    <div style={{ width: (width > 1050 ? 400 : "100%"), paddingRight: (width > 1050 ? 24 : 0), paddingBottom: (width > 1050 ? 0 : 24) }}>
                        <div style={{ paddingBottom: 24 }}>
                            <div className='card-purchase-item'>
                                <div style={{ display: "grid" }}>
                                    <div style={{ paddingBottom: 12 }}>
                                        <label className='ks-item-title'>รายการรับซื้อ</label>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <img src={deviceInfo?.mobileModelImgUrl ?? ""} style={{ width: "100%", objectFit: 'cover' }} />
                                    </div>

                                    <div style={{ textAlign: "center", paddingTop: 12 }}>
                                        <label style={{ fontSize: 20 }}>{deviceInfo?.mobileModelTitle ?? "-"}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ paddingBottom: 24 }}>
                            <div className='card-evaluation-result'>
                                <div style={{ display: "grid" }}>
                                    <div style={{ padding: "24px 24px 0px 24px" }}>
                                        <label className='ks-item-detail'>ผลการประเมิน</label>
                                    </div>

                                    <ul style={{ display: "grid", padding: "0px 24px 0px 40px" }}>
                                        {issueInfo?.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <label style={{ fontWeight: 400 }}>{item?.title ?? "-"}</label>
                                                </li>
                                            )
                                        })}
                                    </ul>

                                    <div
                                        style={{
                                            textAlign: "center",
                                            padding: 12,
                                            backgroundColor: "#FFBB55",
                                            borderBottomLeftRadius: 8,
                                            borderBottomRightRadius: 8
                                        }}
                                    >
                                        <label className='ks-card-title'>ยอดรวมสุทธิ {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(saleInfo?.summarySaleInfo ?? 0))} บาท</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='purchasing-location'>
                        <div style={{ width: (width > 1050 ? 600 : "100%") }}>
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                <Row gutter={[(width > 430 ? 24 : 0), 0]}>
                                    <Col span={24}>
                                        <div style={{ paddingBottom: 12 }}>
                                            <label className='ks-item-title'>สถานที่รับซื้อ</label>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} style={{ paddingBottom: (width > 992 ? 0 : 12) }}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            style={{ width: '100%', backgroundColor: isSelectPlace === "buy-at-home" ? "" : "#D6D6D6" }}
                                            onClick={() => {
                                                setIsSelectPlace("buy-at-home")
                                            }}
                                        >
                                            <label style={{ cursor: "pointer", color: "black" }}>รับซื้อถึงบ้าน</label>
                                        </Button>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={8} style={{ paddingBottom: (width > 992 ? 0 : 12) }}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            style={{ width: '100%', backgroundColor: isSelectPlace === "buy-along-train" ? "" : "#D6D6D6" }}
                                            onClick={() => {
                                                setIsSelectPlace("buy-along-train")
                                            }}
                                        >
                                            <label style={{ cursor: "pointer", color: "black" }}>รับซื้อตามรถไฟฟ้า</label>
                                        </Button>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <Button
                                            type="primary"
                                            style={{ width: '100%', backgroundColor: isSelectPlace === "buy-at-store" ? "" : "#D6D6D6" }}
                                            onClick={() => {
                                                setIsSelectPlace("buy-at-store")
                                            }}
                                            size="large"
                                        >
                                            <label style={{ cursor: "pointer", color: "black" }}>ไปขายที่หน้าร้าน</label>
                                        </Button>
                                        <label style={{ color: "red", fontSize: 13 }}>* มาขายที่หน้าร้านรับเพิ่ม 500 บาท</label>
                                    </Col>

                                    <Col span={24}>
                                        <div style={{ paddingTop: 24 }}>
                                            <Form.Item
                                                name="name"
                                                label="ชื่อ-นามสกุล"
                                                rules={[{ required: true, message: 'กรุณากรอกชื่อ-นามสกุล' }]}
                                            >
                                                <Input size="large" placeholder="กรอกข้อมูล" style={{ width: "100%" }} />
                                            </Form.Item>
                                        </div>
                                    </Col>

                                    {isSelectPlace === "buy-at-home" ?
                                        <>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="address"
                                                    label={
                                                        <div >
                                                            ที่อยู่ <span style={{ paddingLeft: 12, color: "red" }}>หากต้องการขายด่วนภายในวันนี้ กรุณาติดต่อ 090-924-3388</span>
                                                        </div>}
                                                    rules={[{ required: true, message: 'กรุณากรอกที่อยู่' }]}
                                                >
                                                    <Input size="large" placeholder="กรอกข้อมูล" style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} sm={24} md={12} lg={12}>
                                                <Form.Item
                                                    name="province"
                                                    label="จังหวัด"
                                                    rules={[{ required: true, message: 'กรุณาเลือกจังหวัด' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="เลือกรายการ"
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        size="large"
                                                        onChange={(e) => {
                                                            // console.log("Y : ", e)
                                                            getDistrict(e)
                                                        }}
                                                    >
                                                        {province?.map((item, index) => {
                                                            return (
                                                                <Option key={index} value={item.id}>{item.title}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} sm={24} md={12} lg={12}>
                                                <Form.Item
                                                    name="district"
                                                    label="เขต/อำเภอ"
                                                    rules={[{ required: true, message: 'กรุณาเลือกเขต/อำเภอ' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="เลือกรายการ"
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        size="large"
                                                    >
                                                        {district?.map((item, index) => {
                                                            return (
                                                                <Option key={index} value={item.id}>{item.title}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </>
                                        : []
                                    }

                                    {isSelectPlace === "buy-along-train" ?
                                        <>
                                            <Col xs={24} sm={24} md={12} lg={12}>
                                                <Form.Item
                                                    name="trainLine"
                                                    label="สายรถไฟฟ้า"
                                                    rules={[{ required: true, message: 'กรุณาเลือกสายรถไฟฟ้า' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="เลือกรายการ"
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        size="large"
                                                        onChange={(e) => {
                                                            getTrainStation(e)
                                                        }}
                                                    >
                                                        {trainLine?.map((item, index) => {
                                                            return (
                                                                <Option key={index} value={item.id}>{item.title}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} sm={24} md={12} lg={12}>
                                                <Form.Item
                                                    name="trainStation"
                                                    label="สถานีไฟฟ้า"
                                                    rules={[{ required: true, message: 'กรุณาเลือกสถานีไฟฟ้า' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="เลือกรายการ"
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        size="large"
                                                    >
                                                        {trainStation?.map((item, index) => {
                                                            return (
                                                                <Option key={index} value={item.id}>{item.title}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </>
                                        : []
                                    }

                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item
                                            name="day"
                                            label="วัน"
                                            rules={[{ required: true, message: 'กรุณาเลือกวัน' }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="เลือกรายการ"
                                                style={{ width: '100%' }}
                                                optionFilterProp="children"
                                                allowClear
                                                size="large"
                                            >
                                                {day?.map(item => {
                                                    return (<>{item}</>)
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item
                                            name="time"
                                            label="เวลา"
                                            rules={[{ required: true, message: 'กรุณาเลือกเวลา' }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="เลือกรายการ"
                                                style={{ width: '100%' }}
                                                optionFilterProp="children"
                                                allowClear
                                                size="large"
                                            >
                                                {time?.map(item => {
                                                    return (<>{item}</>)
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item
                                            name="phoneNumber"
                                            label="หมายเลขโทรศัพท์"
                                            rules={[{ required: true, message: 'กรุณากรอกหมายเลขโทรศัพท์' }]}
                                        >
                                            <Input size="large" placeholder="กรอกข้อมูล" style={{ width: "100%" }} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item
                                            name="confirmPhoneNumber"
                                            label="ยืนยันเบอร์โทร"
                                            rules={[{ required: true, message: 'กรุณากรอกยืนยันเบอร์โทร' }]}
                                        >
                                            <Input size="large" placeholder="กรอกข้อมูล" style={{ width: "100%" }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <div style={{ paddingBottom: 24 }}>
                                            <Checkbox
                                                checked={policy} onChange={() => { setPolicy(!policy) }}
                                            >
                                                ฉันยอมรับข้อตกลงและเงื่อนไข <span onClick={() => { setModalPolicy({ isShow: true }) }} style={{ color: "#FF9900" }}>การรับซื้อสินค้าของทาง Kingdom Store</span>
                                            </Checkbox>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>

                        <div>
                            <Button
                                type="primary"
                                size="large"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    window.scroll(0,0)
                                    form.submit()
                                }}
                            >
                                <label style={{ cursor: "pointer", color: "black" }}>ส่งให้ร้าน</label>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>



            <Modal
                title={"ข้อตกลงและเงื่อนไข"}
                visible={modalPolicy.isShow}
                zIndex={999}
                onCancel={() => {
                    setModalPolicy({
                        isShow: false
                    })
                }}
                width={700}
                onOk={() => {

                }}

                footer={[]}
            >
                ...
            </Modal>

            <Footer />
        </>
    )
}